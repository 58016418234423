export const PARTNERLOGOS: {
  topbarLogo: Record<string, string>;
} = {
  topbarLogo: {
    '5bc8bdb338863c5d652b2d8f': '5bc8bdb338863c5d652b2d8f.svg',
    '5bf36c7583a1a31e8d39ae9f': '5bf36c7583a1a31e8d39ae9f.svg',
    '5caf7e90d1eb757c418451ed': '5caf7e90d1eb757c418451ed.svg',
    '5cb0c06aa78f465a2265fba8': '5cb0c06aa78f465a2265fba8.svg',
    '5d44deedeb9587204716da1c': '5d44deedeb9587204716da1c.svg',
    '5d680c4b8340357ea0c25a6e': '5d680c4b8340357ea0c25a6e.svg',
    '5d6ffba98340357ea0c2768a': '5d6ffba98340357ea0c2768a.svg',
    '5db8b92e1d8e0d33dd9a1112': '5db8b92e1d8e0d33dd9a1112.svg',
    '5dbacea86cd29c38a59eb70f': '5dbacea86cd29c38a59eb70f.svg',
    '5dc4228f1d8e0d33dd9a5e7b': '5dc4228f1d8e0d33dd9a5e7b.svg',
    '5de7de32a57f76229610add2': '5de7de32a57f76229610add2.svg',
    '5e666a14cf693f5757f26ab6': '5e666a14cf693f5757f26ab6.svg',
    '5ea1d947a02352248c4829e4': '5ea1d947a02352248c4829e4.svg',
    '5f6b7055a3747425ace88228': '5f6b7055a3747425ace88228.svg',
    '5fa58c4feb1e5a05cdde8be5': '5fa58c4feb1e5a05cdde8be5.svg',
    '603e428a07d4ec264826c536': '603e428a07d4ec264826c536.svg',
    '605097e82b5d3d4f6fb1f868': '605097e82b5d3d4f6fb1f868.svg',
    '60867864f752f27e5e96919b': '60867864f752f27e5e96919b.svg',
    '609a30c3040f78025db3ed9a': '609a30c3040f78025db3ed9a.svg',
    '609a3a27040f78025db3ed9b': '609a3a27040f78025db3ed9b.svg',
    '609bb51692ef5c4c51f2d381': '609bb51692ef5c4c51f2d381.svg',
    '60c0cf4d93755360d1ccab9e': '60c0cf4d93755360d1ccab9e.svg',
    '60dec4cc2ee11a0dce37ae00': '60dec4cc2ee11a0dce37ae00.svg',
    '60fe99aeab05425087d71a61': '60fe99aeab05425087d71a61.svg',
    '610a511cffddc874e3b9624f': '610a511cffddc874e3b9624f.svg',
    '610a5fe0d29ed274ea824885': '610a5fe0d29ed274ea824885.svg',
    '6110f3e529697a5251bd6680': '6110f3e529697a5251bd6680.svg',
    '611a073726a7320a185ad6fb': '611a073726a7320a185ad6fb.svg',
    '611a0b7ac0297c0b2e61de59': '611a0b7ac0297c0b2e61de59.svg',
    '611a0cd726a7320a185ad6fc': '611a0cd726a7320a185ad6fc.svg',
    '6143521736a2ee216cd5718c': '6143521736a2ee216cd5718c.svg',
    '61519883b8aaab4458cbdcb4': '61519883b8aaab4458cbdcb4.svg',
    '615c4b27792ac62410c0c1ed': '615c4b27792ac62410c0c1ed.svg',
    '615c4c3b7eeb7c2524686ef3': '615c4c3b7eeb7c2524686ef3.svg',
    '615c565d5956a929f2169713': '615c565d5956a929f2169713.svg',
    '615c58429e4e6d2b1b28a140': '615c58429e4e6d2b1b28a140.svg',
    '6169e028b7a3e729c3218db1': '6169e028b7a3e729c3218db1.svg',
    '619e1c013b2f9f791254a39e': '619e1c013b2f9f791254a39e.svg',
    '61ae33fa2f2e2550f4ff8d4f': '61ae33fa2f2e2550f4ff8d4f.svg',
    '62aadea80327d8000b26198b': '62aadea80327d8000b26198b.svg',
    '62b1a950b7ff06000a2b8716': '62b1a950b7ff06000a2b8716.svg',
    '634945302022130053625a67': '634945302022130053625a67.svg',
    '63590ddc903154002348745a': '63590ddc903154002348745a.svg',
    '63d7b715c1ebaf000b6963ed': '63d7b715c1ebaf000b6963ed.svg',
    '5f6c9292a3747425ace8863c': '5f6c9292a3747425ace8863c.svg',
    '5d82dce371c00c22c111397e': '5d82dce371c00c22c111397e.svg',
    '618001dee113cf2a52f84752': '618001dee113cf2a52f84752.svg',
    'qa-sso-partner1': 'qa-sso-partner1.svg',
    '6051060eeb01964ff68fc9b3': '6051060eeb01964ff68fc9b3.svg',
    '6410c832735697000adc7d0b': '6410c832735697000adc7d0b.svg',
    '6410cb3b4ce01a000a7e1be5': '6410cb3b4ce01a000a7e1be5.svg',
    '5dac87414940aa0579303925': '5dac87414940aa0579303925.svg',
    '5fbe23f7d55b5e0c2d1994ca': '5fbe23f7d55b5e0c2d1994ca.svg',
  },
};
